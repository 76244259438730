// Main javascript entry point
// Should handle bootstrapping/starting application

'use strict';

import $ from 'jquery';
import Subscribe from '../_modules/subscribe/subscribe';

$(() => {
  new Subscribe(); // Activate modules
});
