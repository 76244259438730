'use strict';
const approve = require('approvejs');
const request = require('request');

export default class Subscribe {
  constructor() {
    const rules = {
      first_name: {
        required: {
          message: 'Please enter a first name'
        }
      },
      last_name: {
        required: {
          message: 'Please enter a last name'
        }
      },
      email_address: {
        required: {
          message: 'Please enter an email address'
        },
        email: {
          message: 'Please enter a valid email address'
        }
      }
    };

    function addInvalid(group, error) {
      const input = group.getElementsByClassName('form-control')[0];
      input.classList.add('is-invalid');
      group.insertAdjacentHTML('beforeEnd', `<div class="invalid-feedback">${error}</div`);
    }

    function removeInvalid(group) {
      const helperText = group.getElementsByClassName('invalid-feedback')[0];
      const input = group.getElementsByClassName('form-control')[0];
      if (helperText) {
        input.classList.remove('is-invalid');
        helperText.remove();
      }
    }

    function isApproved(group) {
      removeInvalid(group);

      const input = group.getElementsByClassName('form-control')[0];
      const result = approve.value(input.value, rules[input.name]);
      if (!result.approved) {
        addInvalid(group, result.errors[0]);
      }
      return (result.approved);
    }

    function addRecipient() {
      const contact = {
        'first_name': document.getElementsByName('first_name')[0].value,
        'last_name': document.getElementsByName('last_name')[0].value,
        'email': document.getElementsByName('email_address')[0].value
      };

      request.post({
        url: 'https://us-central1-dashofagile-static.cloudfunctions.net/subscribe',
        json: true,
        body: contact
      }, (err, res, body) => {
        if (res.body === 'added') {
          document.getElementById('signup-form').classList.add('hide');
          document.getElementById('signup-added').classList.remove('hide');
        } else {
          document.getElementById('signup-form').classList.add('hide');
          document.getElementById('signup-exists').classList.remove('hide');
        }
      });
    }

    function validate(form) {
      const groups = form.getElementsByClassName('form-group');
      let approved = true;
      Array.from(groups).forEach(group => {
        approved = isApproved(group);
      });

      if (approved) {
        addRecipient();
      } else {
        document.getElementById('signup-btn').classList.remove('disabled');
      }
    }

    const form = document.querySelector('form#signup');
    form.addEventListener('submit', ev => {
      ev.preventDefault();
      document.getElementById('signup-btn').classList.add('disabled');
      validate(form);
    });

    const elements = form.getElementsByClassName('form-control');
    Array.from(elements).forEach(element => {
      element.addEventListener('blur', ev => {
        if (ev.target.classList.contains('is-invalid')) {
          validate(form);
        }
      });
    });
  }
}
